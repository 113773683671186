export class PTicket {

  /**
   * Provides a string containing CSS styles to define the appearance of a web page.
   *
   * @returns A `<style>` block as a string, containing styles for common HTML elements.
   */
  static getPageStyle(): string {
    return `
<style>
  button {
    background-color: #293b36;
    color: white;
    border-radius: 4px;
    border-style: none;
    text-align: center;
    cursor: pointer;
    font-weight: 500;
    font-size: 14px;
    padding: 0 16px;
    line-height: 36px;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI',
      Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue',
      sans-serif;
  }

  body {
    margin: 0;
    font-family: monospace;
  }

  header {
    width: 80mm;
    display: flex;
    justify-content: center;
    padding: 16px 0;
  }

  main {
    border: 1px solid #293b36;
    width: 80mm;
    padding: 5mm;
  }

  img {
    display: block;
    margin: 0 auto;
    margin-bottom: 16px;
    height: 40mm;
  }

  h1,
  h2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  h1,
  .footer {
    font-size: 30px;
    text-align: center;
  }

  h2 {
    font-size: 20px;
  }

  hr {
    border: 1px solid black;
  }

  p {
    margin: 0;
  }

  @media print {
    header {
      display: none;
    }
  }
</style>
    `;
  }

  /**
   * Generates an HTML `<img>` element for a logo, based on the provided payload.
   *
   * @param payload - An object containing:
   *    - `ticketLogo` (Optional): The URL of the logo image. If not provided, an empty string is returned.
   *    - `locationName`: The name of the location, used for the `alt` attribute of the image.
   *
   * @returns An HTML string representing an `<img>` element:
   *          - Includes the `src` attribute pointing to `ticketLogo`.
   *          - Includes the `alt` attribute set to `${locationName} Logo`.
   *          - Returns an empty string if `ticketLogo` is undefined or empty.
   *
   * Examples:
   * - `getLogo({ ticketLogo: "logo.png", locationName: "Office" })`
   *   -> `<img src="logo.png" alt="Office Logo" />`
   * - `getLogo({ locationName: "Store" })` -> `''`
   */
  static getLogo(payload: {
    ticketLogo?: string;
    locationName: string;
  }): string {
    if (!payload.ticketLogo) return '';

    return `
      <img
        src="${payload.ticketLogo}"
        alt="${payload.locationName} Logo"
      />`;
  }

  /**
   * Generates an HTML string containing a message wrapped in a specified tag.
   *
   * @param message - (Optional) The text message to be wrapped. If not provided, an empty string is returned.
   * @param htmlTag - The HTML tag to wrap the message with. Defaults to `'p'`.
   *
   * @returns A formatted HTML string in the form `<htmlTag>message</htmlTag>`.
   *          If `message` is undefined or empty, an empty string is returned.
   *
   * Examples:
   * - `getMessage("Hello", "h1")` -> `<h1>Hello</h1>`
   * - `getMessage("World")` -> `<p>World</p>`
   * - `getMessage()` -> `''`
   */
  static getMessage(message?: string, htmlTag = 'p'): string {
    if (!message) return '';

    return `<${htmlTag}>${message}</${htmlTag}>`;
  }

  /**
   * Opens a new browser window to display printable ticket content and manages
   * its settings.
   *
   * @param payload - An object containing:
   *    - `ticketWindowContent`: The HTML content to display in the print window.
   *    - `toast`: A callback function executed if the print window cannot be opened.
   *    - `windowOpts`: Options for the print window, including:
   *        - `title`: The title of the print window.
   */
  static print(payload: {
    ticketWindowContent: string;
    toast: () => void;
    windowOpts: { title: string };
  }): void {
    const { ticketWindowContent, toast, windowOpts } = payload;

    const height = 1000;
    const width = 380;
    const left = window.innerWidth / 2 - width / 2;
    const top = window.innerHeight / 2 - height / 2;

    const windowFeatures = `width=${width},height=${height},left=${left},top=${top},resizable=yes,scrollbars=yes`;

    const printTicketWindow = window.open('', '_blank', windowFeatures);

    if (!printTicketWindow) {
      toast();

      return;
    }

    printTicketWindow.document.title = windowOpts.title;
    printTicketWindow.document.open();
    printTicketWindow.document.write(ticketWindowContent);
    printTicketWindow.document.close();
  }
}
