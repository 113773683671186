import * as moment from 'moment';

export class PDate {
  /**
   * Returns the current time zone.
   *
   * @returns {string} The current time zone.
   */
  static getTimeZome(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  /**
   * Returns the current time zone with the format `UTC±HH:mm`.
   *
   * @returns {string} The current time zone.
   */

  static getTimeZone(): string {
    const offset = new Date().getTimezoneOffset();
    const hours = Math.floor(Math.abs(offset) / 60);
    const minutes = Math.abs(offset) % 60;
    const sign = offset < 0 ? '+' : '-';

    return `UTC${sign}${hours.toString().padStart(2, '0')}:${minutes
      .toString()
      .padStart(2, '0')}`;
  }

  /**
   * Formats a date with Moment.js library with `DD/MM/YYYY, HH:mm a` format.
   *
   * Example: 01/01/2021, 12:00 PM
   * @param date - The date to be formatted.
   * @returns The formatted date string.
   */
  static getFormatedFullDateForReport(date: Date | string): string {
    return moment(date).format('DD/MM/YYYY, hh:mm a');
  }
}
