import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { PDate } from 'app/common';

@Injectable()
export class TimezoneInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    const modifiedRequest = request.clone({
      headers: request.headers.set('timezone', PDate.getTimeZone()),
    });

    return next.handle(modifiedRequest);
  }
}
